import "./loginScreen2Styles.css";

import vectorArt from "../../assets/signup-screen2.svg";
import dotsBottom from "../../assets/login-dots-bottom.svg";
import dotsTop from "../../assets/login-dots-top.svg";
import logo from "../../assets/top-nav-logo.svg";

import { useEffect, useRef, useState } from "react";

import { loginScreen2Validation } from "../../request-handlers/validation/request-validation";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/user/user";
import {
  saveAccountDetails,
  saveSelectedOrg,
  setAllOrgsDetails,
} from "../features/account/account";

import clevertap from "clevertap-web-sdk";
import { pushToClevertap } from "../../utility-functions/clevertap";
import { JService } from "../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_BETA;

export default function LoginScreen2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLoginAttemptWith = useSelector((state) => state.user.value);

  const [loginScreen2Values, setLoginScreen2Values] = useState({
    "1": "",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
  });

  function listenToPasteEvent(e) {
    const fromClipboard = (e.clipboardData || window.clipboardData).getData("text")?.toUpperCase();
    const values = structuredClone(loginScreen2Values)
	Array.from(fromClipboard).forEach((c, index) => {
		values[index+1] = c
	})
	setLoginScreen2Values(values)
  }

  const field1Ref = useRef();
  const field2Ref = useRef();
  const field3Ref = useRef();
  const field4Ref = useRef();
  const field5Ref = useRef();
  const field6Ref = useRef();

  useEffect(() => {
    field1Ref.current.focus();
    document.addEventListener("paste", listenToPasteEvent);
    return () => document.removeEventListener("paste", listenToPasteEvent)
  }, []);

  // NOTE: maxLen on input prevents onCHange to be fired when cell is not empty
  // FIXME: backspace/del should remove existing content and stay in the same box
  // if field already empty backspace pushed focus to left, del to the right
  const handleChange = (e) => {
    const field = e.target.dataset.loginscreen2;
    const value = e.target.value;

	// disallow spacebars
    if(value === " ") return;

	const mapping = {
		"1": {
			ondeleteFocus: field1Ref,
			oninsertFocus: field2Ref,
			selfFocus: field1Ref,
		},
		"2": {
			ondeleteFocus: field1Ref,
			oninsertFocus: field3Ref,
			selfFocus: field2Ref,
		},
		"3": {
			ondeleteFocus: field2Ref,
			oninsertFocus: field4Ref,
			selfFocus: field3Ref,
		},
		"4": {
			ondeleteFocus: field3Ref,
			oninsertFocus: field5Ref,
			selfFocus: field4Ref,
		},
		"5": {
			ondeleteFocus: field4Ref,
			oninsertFocus: field6Ref,
			selfFocus: field5Ref,
		},
		"6": {
			ondeleteFocus: field5Ref,
			oninsertFocus: field6Ref,
			selfFocus: field6Ref,
		},
	}

	const inputType = e.nativeEvent.inputType;

	// backspace moves to left and del moves to right
	// backspace
	if(inputType === "deleteContentBackward") {
		mapping[field].ondeleteFocus.current.focus()
	}
	else if(inputType === "deleteContentForward") {
		mapping[field].oninsertFocus.current.focus()
	}
	else if(value || value == 0) {
		// focus next
		mapping[field].oninsertFocus.current.focus()
	}
	
	const update = {...loginScreen2Values, [field]: value.toUpperCase()}
	setLoginScreen2Values(update)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(loginScreen2Validation(loginScreen2Values)) {
		const payload = {
			email: userLoginAttemptWith.email,
			code: Object.values(loginScreen2Values).join(""),
			source: "login"
		}

		fetch(API_BASE_URL + "/api/v1/auth/validate-code/?webapp=True", {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
        .then(res => {
        //   if(res?.status === 500) throw new Error("Server error please try again or if issue persists contact FreJun");
          return res?.json();
        })
        .then((res) => {
			if(!res?.success)  throw new Error(Array.isArray(res?.message) ? res?.message[0] ?? "Failed to login" : res?.message ?? "Failed to login")
			
			// successfully authenticated
			dispatch(login(userLoginAttemptWith));
			// retrieve account profile
			fetch(API_BASE_URL + "/api/v1/auth/retrieve-account-profile/", {
				method: "get",
				credentials:'include'
			})
			.then((res) => {
				if (res?.status === 500)
				throw new Error(
					"Server error please try again or if issue persists contact FreJun"
				);
				return res?.json();
			})
            .then((_res) => {
              if(!_res?.success) throw new Error(_res?.message || "Failed to fetch account details");

              	dispatch(saveAccountDetails(_res));

				let userDetails = _res?.data;
				let server = new URL(API_BASE_URL);
				server = server.host;

				let role =
					userDetails?.profile?.role == 1
					? "super admin"
					: userDetails?.profile?.role == 4
					? "admin"
					: userDetails?.profile?.role == 2
					? "user"
					: userDetails?.profile?.role == 3
					? "viewer"
					: "unknown"; // eslint-disable-line
				let integrations = "";
				if (userDetails?.profile?.hubspot_access)
					integrations += "hubspot";

				if (userDetails?.profile?.monday_access) {
					if (!integrations) integrations += "monday";
					else integrations += ", monday";
				}

				if (userDetails?.profile?.deskera_access) {
					if (!integrations) integrations += "deskera";
					else integrations += ", deskera";
				}

				if (userDetails?.profile?.pipedrive_access) {
					if (!integrations) integrations += "pipedrive";
					else integrations += ", pipedrive";
				}

				if (userDetails?.profile?.zoho_phonebridge_access) {
					if (!integrations) integrations += "zohophonebridge";
					else integrations += ", zohophonebridge";
				}
				if (userDetails?.profile?.zoho_access) {
					if (!integrations) integrations += "zoho";
					else integrations += ", zoho";
				}

				let payload = {
					Name: userDetails?.first_name + " " + userDetails?.last_name, // fullname
					Identity: userDetails?.email, //email,
					Email: userDetails?.email,

					Phone:
					userDetails?.number?.country_code +
					"" +
					userDetails?.number?.phone_number, // string | number, // user phone number
					OrganizationName: userDetails?.profile?.org_name || "",
					OrganizationId: userDetails?.profile?.org_id,
					VirtualNumbers: JSON.stringify(userDetails?.virtual_numbers),
					SuperAdmin: userDetails?.account_holder ? "yes" : "no",
					Role: role,
					IsVerified: userDetails?.profile?.is_verified
					? "verified"
					: "not verified",
					TotalMinutes: userDetails?.profile?.total_minutes,
					Integrations: integrations,
					InboundCalls: userDetails?.profile?.inbound_calls
					? "enabled"
					: "disabled",
					UserEmail: userDetails?.email,
					UserPhoneNumber:
					userDetails?.number?.country_code +
					"" +
					userDetails?.number?.phone_number, // string | number, // user phone number
					BrowserCalls: userDetails?.access_list?.includes('BROWSER CALLING')
					? "enabled"
					: "disabled",
					NotifyEmails: userDetails?.profile?.notify_emails
					? "true"
					: "false",
					MissedCallNotifications: userDetails?.profile
					?.missed_call_notifications
					? "true"
					: "false",
					PrimaryVirtualNumber: JSON.stringify(
					userDetails?.profile?.primary_virtual_number
					),
					FeatureAddOns: JSON.stringify(
					userDetails?.profile?.feature_add_ons || []
					),
				};
				clevertap.onUserLogin.push({ Site: payload });

				pushToClevertap("User login", true);

              	// retrieve orgs
				if (_res?.data?.profile?.account_holder) {
					JService.get("/api/v1/auth/organizations/")
					.then((__res) => {
						if (!__res?.success) throw new Error(__res?.message || "Could not retrieve organizations");

						dispatch(setAllOrgsDetails(__res?.data));
						dispatch(saveSelectedOrg(__res?.data?.find((rd) => rd?.primary_account)?.id || ""));

						// loginRedirect() // enable
						const _loginRedirect = localStorage.getItem("frejun-login-redirect");
						if (!_loginRedirect || Object.keys(_loginRedirect)?.length === 0) {
							navigate("/call-logs", {replace: true});
						} 
						else if (JSON.parse(_loginRedirect)?.isRead === false) {
							navigate(JSON.parse(_loginRedirect)?.url || "/call-logs", {replace: true})
							localStorage.setItem("frejun-login-redirect", JSON.stringify({
								url: JSON.parse(_loginRedirect)?.url,
								isRead: true,
							}));
						}
					})
					.catch((__err) => enqueueSnackbar(__err?.message, { variant: "error"}))
				} 
				else {
					const _loginRedirect = localStorage.getItem("frejun-login-redirect");
					if(!_loginRedirect || Object.keys(_loginRedirect)?.length === 0) {
					navigate("/call-logs", {replace: true});
					} 
					else if(JSON.parse(_loginRedirect)?.isRead === false) {
						navigate(JSON.parse(_loginRedirect)?.url || "/call-logs", {replace: true});
						localStorage.setItem("frejun-login-redirect", JSON.stringify({
							url: JSON.parse(_loginRedirect)?.url,
							isRead: true,
						}));
					}
              	}
            })
            .catch((_err) => enqueueSnackbar(_err?.message, { variant: "error"}))
        })
        .catch((err) => enqueueSnackbar(err?.message || "Error occurred while verifying code", { variant: "error"}))
        .finally(() => {
          // reset passCode values
          setLoginScreen2Values({
            "1": "",
            "2": "",
            "3": "",
            "4": "",
            "5": "",
            "6": "",
          });
        });
    }
  };

  const handleResendCode = (e) => {
    const _body = {
      email: userLoginAttemptWith?.email,
      source: "login",
    };
    fetch(API_BASE_URL + "/api/v1/auth/send-code/", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(_body),
    })
      .then((res) => {
        if (res?.status === 500)
          throw new Error(
            "Server error please try again or if issue persists contact FreJun"
          );
        return res?.json();
      })
      .then((res) => {
        if (!res?.success) throw Error(res?.message || "Failed to resend code");
		enqueueSnackbar((res?.message || "Check for the verification code in your email") + ". Look in " + userLoginAttemptWith?.email, { variant: "success"})
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while resending the code", { variant: "error"}))
      .finally(() => {
        // reset otp
        setLoginScreen2Values({
          "1": "",
          "2": "",
          "3": "",
          "4": "",
          "5": "",
          "6": "",
        });
      });
  };
  
  return (
    <>
      <div className="login-screen2-container">
        <div className="signup-art">
		<a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
          <img src={dotsTop} />
          <img src={vectorArt} />
          <img src={dotsBottom} />
        </div>
        <div className="login-screen2-form">
          <p className="title medium-font t5">
            Enter verification code{" "}
          </p>
          <form onSubmit={handleSubmit}>
            <p className="sub-title regular-font t7">
              Please enter the verfication code sent to <br />{" "}
              <u className="medium-font t7">
                {userLoginAttemptWith?.email}
              </u>
            </p>
            <div className="fields">
              <input
                ref={field1Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="1"
                onChange={handleChange}
                value={loginScreen2Values[1]}
              />
              <input
                ref={field2Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="2"
                onChange={handleChange}
                value={loginScreen2Values[2]}
              />
              <input
                ref={field3Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="3"
                onChange={handleChange}
                value={loginScreen2Values[3]}
              />
              <input
                ref={field4Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="4"
                onChange={handleChange}
                value={loginScreen2Values[4]}
              />
              <input
                ref={field5Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="5"
                onChange={handleChange}
                value={loginScreen2Values[5]}
              />
              <input
                ref={field6Ref}
                type="text"
                maxLength="1"
                autoComplete="none"
                data-loginscreen2="6"
                onChange={handleChange}
                value={loginScreen2Values[6]}
              />
            </div>
            <hr />
            <p className="send-again regular-font t7">
              Didn't receive the email?{" "}
              <span
                className="color-green"
                onClick={handleResendCode}
              >
                Send again
              </span>
            </p>
            <input
              id="login-passcode-verify"
              type="submit"
              className="btn btn-green t6 medium-font"
              value="Verify"
              onSubmit={handleSubmit}
			  disabled={!loginScreen2Validation(loginScreen2Values)}
            />
          </form>
        </div>
      </div>
    </>
  );
}
