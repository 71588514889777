import { Box, Chip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import colors from "../design/colors";
import styles from "../design/styles";
import chipDeleteIcon from "../../assets/gusers-and-teams-chip-delete-icon.svg";
import keyShowIcon from "../../assets/password-show.svg";
import keyHideIcon from "../../assets/password-hide.svg";
import { JService } from "../api-service/ApiService";
import AnimationPage from '../shared/animation-page/AnimationPage';
import { CalendarIcon } from "../../utility-functions/assetLoader";
import { enqueueSnackbar } from 'notistack';
import {Button} from "@mui/material";
import ToggleFeature from "../UI/ToggleFeature"
import { CustomChip, CustomSwitch } from "../design/components/customComponents";
import GModal from "../design/components/GModal";
import { DatePicker } from "rsuite";
import { getOrgURLParam } from "../../utility-functions";


function AccountTab(props) {
  const { handleUpdateOrganization } = props;
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const reduxAccount = useSelector((state) => state?.account?.value);

  const [organization, setOrganization] = useState(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [controller, setController] = useState(0);

  const [newCallOutcome, setNewCallOutcome] = useState("");
  const [newCallReason, setNewCallReason] = useState("");
  const [reportScheduler, setReportScheduler] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [dailyReportTime, setDailyReportTime] = useState("");



  const handleAddNewOutcome = (e) => {
    if (!newCallOutcome) return;
    e.preventDefault();
    if (organization.call_outcomes.includes(newCallOutcome)) {
	  enqueueSnackbar("Call Outcome already exists", { variant: "error"})
      return;
    }

    // Add check for account

    let updatedOutcomes = organization.call_outcomes.concat([newCallOutcome]);
    const updatedOrganization = {
      ...organization,
      call_outcomes: updatedOutcomes,
    };

    setOrganization(updatedOrganization);
    handleUpdateOrganization(updatedOrganization);
    setNewCallOutcome("");
  };

  const handleAddNewReason = (e) => {
    if (!newCallReason) return;
    e.preventDefault();
    if (organization.call_reasons.includes(newCallReason)) {
	  enqueueSnackbar("Call reason already exists", { variant: "error"})
      return;
    }

    // Add check for account

    let updatedReasons = organization.call_reasons.concat([newCallReason]);
    const updatedOrganization = {
      ...organization,
      call_reasons: updatedReasons,
    };

    setOrganization(updatedOrganization);
    handleUpdateOrganization(updatedOrganization);
    setNewCallReason("");
  };

  const handleDeleteReason = (reason) => {
    if (Array.isArray(organization.call_reasons)) {
      const updatedReasons = organization.call_reasons.filter(
        (value) => value != reason
      );

      // Add check for account

      setOrganization({
        ...organization,
        call_reasons: updatedReasons,
      });
      handleUpdateOrganization({
        ...organization,
        call_reasons: updatedReasons,
      });
    }
  };

  const handleDeleteOutcome = (outcome) => {
    if (Array.isArray(organization.call_outcomes)) {
      const updatedOutcomes = organization.call_outcomes.filter(
        (value) => value != outcome
      );

      // Add check for account

      setOrganization({ ...organization, call_outcomes: updatedOutcomes });
      handleUpdateOrganization({
        ...organization,
        call_outcomes: updatedOutcomes,
      });
    }
  };

  useEffect(() => {
    if (props.organization) {
      setOrganization(props.organization);
    }
  }, [props.organization]);

//   useEffect(() => {
//     if (!props.organization || !organization) return;
//     const toggleFields = [
//       "daily_reports",
//       "two_factor_auth",
//       "private_recording",
// 	  "record_warning"
//     ];
//     for (const field of toggleFields) {
//       if (props.organization[field] !== organization[field]) {
//         handleUpdateOrganization(organization);
//         break;
//       }
//     }
//   }, [props.organization, organization, handleUpdateOrganization]);

  useEffect(() => {
    setTagValue("");

    JService.get(`/api/v1/auth/retrieve-tags/${getOrgURLParam()}`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not retreive tags");
        setAllTags(res?.data?.map((rd) => ({ name: rd?.name, id: rd?.id })));
        // dispatchAvailableData({type: 'setPayloadToAllOfType', payload:{payloadFor: 'tags', payload: res?.data?.map(rd => ({name: rd?.name, id: rd?.id}))}})
      })
      .catch((err) => console.warn(err?.message || "Failed to fetch tags"));
    // setSnackbar(prevState => ({...prevState, data:{...prevState?.data, type: 'error', message: err?.message || 'Failed to retreive tags' }, open: true})))
  }, [controller]);

  const handleAddTag = (e) => {
    // check if tag is already added
    e.preventDefault();
    if (!tagValue) return;
    let itemIndex = allTags.findIndex((tag) => tag?.name === tagValue);
    if (itemIndex !== -1) {
	  enqueueSnackbar("Tag already exists", { variant: "error"})
      return;
    }
    // tag not found in allTags
    // add-tag api call

    JService.post(`/api/v1/auth/tag/${getOrgURLParam()}`, {
      name: tagValue,
    })
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not create tag");
        // tag created
		enqueueSnackbar(res?.message, { variant: "success"})

        if(res?.name?.length && (res?.id || res?.id == 0)) setAllTags(prev => [...prev, { name: res?.name, id: res?.id }]);
		else setController(prev => ++prev)
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };
	const fetchOrganizationData = async () => {
	  try {
		const response = await JService.get('/api/v1/auth/organizations/');
		const dailyReportTime = response.data?.[0]?.daily_report_time;
		if (dailyReportTime) {
		  setDailyReportTime(dailyReportTime);
		} else {
		  enqueueSnackbar('Daily report time not found', { variant: 'error' });
		}
	  } catch (err) {
		enqueueSnackbar(err?.message, { variant: 'error' });
	  }
	};
	useEffect(() => {
	fetchOrganizationData();
  }, []);
  
  const handleToggle = async (event, name) => {
    console.log("inside", name, event.target.checked);
    // const name = event.target.name;
    const value = event.target.checked;
	setOrganization((prevState) => {
		return { ...prevState, [name]: value };
	  });
	const res = await handleUpdateOrganization({...organization,[name]:value})
    if(res && 'success' in res && !res.success){
		setOrganization(prev=>(res.organization || {...prev, [name]:!value}));
	}
	
  };  
  const handleSaveChanges = () => {
    JService.patch(`/api/v1/auth/update-organization/`, {
        daily_report_time: organization.daily_report_time,
    })
    .then((res) => {
        if (res?.success) {
            enqueueSnackbar(res?.message, { variant: "success" });
			setUnsavedChanges(false); 
        }
        setReportScheduler(false);
    }) 
    .catch((err) => {
        console.error("Error saving changes:", err);
        enqueueSnackbar(err?.message, { variant: "error" });
    });
};

const handleTimeChange = (value) => {
	if (!(value instanceof Date)) {
	  return; 
	}
	const hours = value.getHours().toString().padStart(2, '0');
	const minutes = value.getMinutes().toString().padStart(2, '0');
	const selectedTime = `${hours}:${minutes}:00`;
  	setOrganization((prevOrganization) => ({
	  ...prevOrganization,
	  daily_report_time: selectedTime,
	}));
	setDailyReportTime(selectedTime)
	setUnsavedChanges(true);
  };
  
  


  const handleTagDelete = (e, tagId) => {
    JService.delete(`/api/v1/auth/tag/?tag_id=${tagId}`)
      .then((res) => {
		// checking res.success === false since response is http 204 No content
        if (res?.success === false) throw new Error(res?.message || "Could not delete tag");
		enqueueSnackbar(res?.message || "Deleted tag", { variant: "success"})
        setController((prevState) => ++prevState);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}));
  };

  const prevReduxOrg = useRef(reduxAccount?.selectedOrg);
  useEffect(() => {
    // reduxAccount changed
	if (
      prevReduxOrg.current !== reduxAccount?.selectedOrg &&
      (Boolean(reduxAccount?.selectedOrg) || reduxAccount?.selectedOrg === 0)
    ) {
      // setorgdetails again if allOrgs?.length > 1 && primaryAccount holder
      prevReduxOrg.current = "";
      // reduxOrg.selectedOrg
      setController((prevState) => ++prevState);
    }
  }, [reduxAccount]);
  const reportSchedulerBody = reportScheduler ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        {/* name */}
        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
        Frequency
          <input
		     readOnly={true}
            type="text"
            className="t6 regular-font nc-gray-900"
            style={{
              marginTop: "4px",
              padding: "8px 12px",
              borderRadius: "4px",
              border: "1px solid #E1E6E2",
            }}
			value={'Daily'}
            data-edittype="name"
          />
        </label>

        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
        <label
        className="t7 nc-gray-900 medium-font"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        Time
		<label
			className="t7 nc-gray-900 medium-font"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "stretch",
			}}
			>
			<div style={{width:'100%'}}>
					<DatePicker
						format="HH:mm"
						style={{width:'100%'}}
						onChange={(value) => handleTimeChange(value)}
						ranges={[]}
						cleanable={false}
						value={dailyReportTime ? new Date(`2000-01-01T${dailyReportTime}`) : null}
						hideMinutes={minute => minute % 5 !== 0}
					/>
					</div>
			</label>

      </label>
        </label>
      </form>
    </Box>
  ) : null;
  const saveButtonStyle = {
    backgroundColor: unsavedChanges ? '#34CB65' : '#BDBDBD', 
    color: 'white',
    width: '65px',
    height: '36px',
    '&:hover': {
        backgroundColor: unsavedChanges ? '#4CAF50' : '#BDBDBD', 
    },
    '&:disabled': {
        backgroundColor: '#F5F7F6', 
    },
};
  return (
	<>
		{organization ? (
		<div className="general-tab">

			<div
			className="section dropdown-section"
			style={{ marginBottom: "40px" }}
			>
			<div className="t5 medium-font nc-gray-900">Dropdowns</div>

			<label>
				Call reason
			</label>
			<Box>
				<form onSubmit={handleAddNewReason}>
				<Box sx={{ ...localStyles.inputContainer, width: '360px' }}>
					<input
					placeholder="Type and press Enter to create"
					style={localStyles.input}
					onChange={(e) => setNewCallReason(e.target.value)}
					onSubmit={handleAddNewReason}
					value={newCallReason}
					className="regular-font t6 nc-gray-900 disabled-input"
					// onClick={handleAddNewReason}
					disabled={organization?.call_reasons?.length > 9}
					/>
				</Box>
				</form>

				<Box sx={{ ...localStyles.chipContainer, width: '560px' }}>
				{/* the display */}
				{organization.call_reasons?.map((reason, index) => (
					<CustomChip
						key={index}
						label={reason}
						onDelete={(e) => handleDeleteReason(reason)}
						style={localStyles.chip}
					/>
				))}
				</Box>
			</Box>
			<label>
				Call outcome
			</label>
			<Box>
				<form onSubmit={handleAddNewOutcome}>
				<Box sx={{ ...localStyles.inputContainer, width: '360px' }}>
					<input
					placeholder="Type and press Enter to create"
					style={localStyles.input}
					onChange={(e) => setNewCallOutcome(e.target.value)}
					onSubmit={handleAddNewOutcome}
					value={newCallOutcome}
					className="regular-font t6 nc-gray-900 disabled-input"
					disabled={organization?.call_outcomes?.length >= 15}
					// onClick={handleAddNewOutcome}
					/>
				</Box>
				</form>

				<Box sx={{ ...localStyles.chipContainer, width: '560px' }}>
				{/* the display */}
				{organization.call_outcomes?.map((outcome, index) => (
					<CustomChip
						key={index}
						label={outcome}
						onDelete={(e) => handleDeleteOutcome(outcome)}
						style={localStyles.chip}
					/>
				))}
				</Box>
			</Box>
			<label>Tags</label>
			<Box>
				<form onSubmit={handleAddTag}>
				<Box sx={{ ...localStyles.inputContainer, width: '360px' }}>
					<input
					placeholder="Type and press Enter to create"
					style={localStyles.input}
					onChange={(e) => setTagValue(e.target.value)}
					onSubmit={handleAddTag}
					value={tagValue}
					className="regular-font t6 nc-gray-900"
					onClick={handleAddTag}
					/>
				</Box>
				</form>

				<Box sx={{ ...localStyles.chipContainer, width: '560px' }}>
				{/* the display */}
				{allTags?.map((tag, index) => (
					<CustomChip
						key={index}
						label={tag?.name}
						onDelete={(e) => handleTagDelete(e, tag?.id)}
						style={localStyles.chip}
					/>
				))}
				</Box>
			</Box>
			</div>

			<div className="settings-sep"></div>

			<div className="section">
			<div className="t5 medium-font nc-gray-900">Notifications</div>
			<label className="switch" style={{ marginTop: "32px" }}>
				<input
				type="checkbox"
				checked={organization.daily_reports}
				onChange={(e) => handleToggle(e, "daily_reports")}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Call reports
				
				
				</p>
				 <div
					className="t6 regular-font nc-gray-600"
					style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
					>
					Get daily call reports through email
					<div>
					<CalendarIcon /> Daily ⋅ {dailyReportTime ? dailyReportTime.slice(0, 5) : 'Not set'} (IST)
						<button
						size="small"
						style={{ color: "#28994B" ,marginLeft:'12px', backgroundColor:'white'}}
						onClick={() => {
							setReportScheduler(true);
						}}
						>
						Edit
						</button>
					</div>
					<GModal
						visible={reportScheduler}
						closeModal={() => {
						setReportScheduler(false);
						setUnsavedChanges(false);
						fetchOrganizationData()
						}}
						heading={"Report scheduler"}
						body={reportSchedulerBody}
						modalStyle={{
						height: "200px",
						width: "368px",
						padding: "32px, 24px, 24px, 24px",
						}}
						bottomButtons={[
						<Button
							onClick={handleSaveChanges}
							disabled={!unsavedChanges}
							sx={saveButtonStyle}
						>
							Save
						</Button>
						]}
					/> 
					</div> 

			</label><br/>
			<label className="switch" style={{ marginTop: "34px" }}>
				<input
				type="checkbox"
				checked={organization.record_warning}
				onChange={(e) => handleToggle(e, "record_warning")}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Call recording announcement
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
				>
				Notify both the caller and recipient that the call is being recorded.
				</p>
			</label>
			{/* <CustomSwitch checked={true} onChange={(e) => handleToggle(e, "rec_announcement")} label="Call recording announcement" description="Notify both the caller and recipient that the call is being recorded." labelStyles={{marginTop:'-65px'}}/> */}
			{/* <ToggleFeature
				title="Call Reports"
				name="daily_reports"
				description="Get daily call reports through e-mail"
				enabled={organization.daily_reports}
				changeHandler={handleToggle}
			/> */}
			</div>

			<div
			style={{ marginTop: "78px", marginBottom: "30px", width: "100%" }}
			className="settings-sep"
			></div>

			<div className="section">
			<div className="t5 medium-font nc-gray-900">Privacy & Security</div>
			<label className="switch" style={{ marginTop: "32px" }}>
				<input
				type="checkbox"
				checked={organization.private_recording}
				onChange={(e) => handleToggle(e, "private_recording")}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Private Recording
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
				>
				Sharable recording links would be private
				</p>
			</label>
			<label className="switch" style={{ marginTop: "48px" }}>
				<input
				type="checkbox"
				checked={organization.two_factor_auth}
				onChange={(e) => handleToggle(e, "two_factor_auth")}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Two factor authentication
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
				>
				Make 2FA as default security verification method for all users
				</p>
			</label>
			{/* <label className="switch" style={{ marginTop: "68px" }}>
				<input
				type="checkbox"
				checked={organization.number_masking}
				onChange={(e) => handleToggle(e, "number_masking")}
				style={{ cursor: "pointer", marginTop: "0" }}
				/>
				<span className="slider round"></span>
				<p
				style={{
					marginTop: "-45px",
					marginLeft: "60px",
					width: "400px",
				}}
				className="t6 regular-font nc-gray-900"
				>
				Number masking
				</p>
				<p
				className="t6 regular-font nc-gray-600"
				style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
				>
				Hide contact's phone numbers
				</p>
			</label> */}
			{/* <ToggleFeature
				title="Private Recording"
				name="private_recording"
				description="Sharable recording links would be private"
				enabled={organization.private_recording}
				changeHandler={handleToggle}
			/>
			<ToggleFeature
				title="2 Factor Authentication"
				name="two_factor_auth"
				description="Make 2FA as default security verification method for all users"
				enabled={organization.two_factor_auth}
				// enabled={true}
				changeHandler={handleToggle}
			/> */}
			</div>
			<div style={{ marginTop: "78px" }} className="settings-sep"></div>
			<div className="section api-section">
			<div className="t5 medium-font nc-gray-900">API Key</div>
			<div style={{ display: "flex", marginTop: "40px" }}>
				<div className="text-box">
				<input
					readOnly
					value={props.apiKey}
					placeholder="Click the button to create API key"
					type={showApiKey ? "text" : "password"}
				/>
				{!!props.apiKey && (
					<button
					className="show-hide-btn"
					onClick={() => setShowApiKey(!showApiKey)}
					>
					<img src={showApiKey ? keyHideIcon : keyShowIcon} />
					</button>
				)}
				</div>
				<button
				className="copy-btn"
				onClick={() => {
					if (props?.apiKey) props.handleCopyApiKey();
					else props.handleGenerateApiKey();
				}}
				>
				{!!props.apiKey ? "Copy" : "Create"}
				</button>
			</div>
			<p className="t6 regular-font nc-gray-900 api-key-description">
				This page provides background information on API keys and
				authentication: how each of these are used, the differences between
				them, and the scenarios where you should consider using API
				keys.&nbsp;
				<a href="https://frejun.com/privacy">Read our Privacy Policy</a>
			</p>
			</div>
		</div>
		) : <AnimationPage />}
	</>
  );
}

export default AccountTab;

const localStyles = {
	
  inputContainer: {
    width: "400px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    width: "100%",
    padding: "8px 12px",
    border: `1px solid ${colors.nc_gray_300}`,
    height: "40px",
    borderRadius: "0px 4px 4px 0px",
    marginBottom: "8px",
  },
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: "400px",
  },
  chip: {
	marginBottom: "4px",
  }
};
