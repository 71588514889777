// # assets
import dotsBottom from "../../assets/login-dots-bottom.svg";
import dotsTop from "../../assets/login-dots-top.svg";
import vectorArt from "../../assets/login-vector-art.png";
import logo from "../../assets/top-nav-logo.svg";

// # css
import "./loginStyles.css";
import colors from "../design/colors";

// # lib
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginValidation } from "../../request-handlers/validation/request-validation";
import {
  saveAccountDetails,
  saveSelectedOrg,
  setAllOrgsDetails,
} from "../features/account/account";
import { login } from "../features/user/user";
import { loginAttempt } from "../features/user/user";

// # components
import Button from "../design/Button";
import clevertap from "clevertap-web-sdk";
import { JService } from "../api-service/ApiService";
import { pushToClevertap } from "../../utility-functions/clevertap";
import { enqueueSnackbar } from 'notistack';

const localStyles = {
  inputField: {
    border: `1px solid ${colors.nc_gray_300}`,
    color: colors.nc_gray_900,
    height: "36px",
  },
  screenOverlay: {
	backgroundColor: "#ffffff77",
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 1302,
	height: window.innerHeight,
	width: window.innerWidth,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
  },
  loggingInText: {
	color: colors.ic_green_500,
	display: "inline-block",
	marginTop: "8px",
  }
};

const API_BASE_URL = process.env.REACT_APP_BETA;
export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reduxAccountRef = useRef(null);

  const userLoginAttemptWith = useSelector((state) => state.user.value);

  const [isSubmitting, setIsSubmitting] = useState(true);
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const handleLoginChange = e => {
	const name = e.target.name
	let value = e.target.value
	if(name === "email") value = value.toLowerCase()
	setLoginValues(prev => ({ ...prev, [name]: value }))
  }

  // TODO: move redirect to redux
  const loginRedirect = () => {
    var redirect = localStorage.getItem("frejun-login-redirect");
    if(redirect) redirect = JSON.parse(redirect)

	// login successful
    if(!redirect || !Object.keys(redirect)?.length) {
		setTimeout(() => navigate("/call-logs", {replace: true}), 50);
    }
	// redirect url not read; redirect to url and update storage
	else if (!redirect?.isRead) {
		localStorage.setItem("frejun-login-redirect", JSON.stringify({ url: redirect?.url, isRead: true }));
		setTimeout(() => navigate("/call-logs", {replace: true}), 50);
    }
  };

  // autologin when user lands on the page
  useEffect(() => {
    JService.get("/api/v1/auth/retrieve-account-profile/")
      .then((res) => {
        if (!res?.success) {
          dispatch(saveAccountDetails({}));
          throw new Error(
            res?.message || "Invalid or empty authentication details provided"
          );
        }
        loginRedirect();
      })
      .catch((err) => {
		console.log(err)
	}).finally(() => {
		resetStates()
	})
  }, []);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const { isValid } = loginValidation(loginValues);
    if(isValid) {
		// saving login attempt values
		dispatch(loginAttempt(loginValues));

		if (!isSubmitting) setIsSubmitting(true);
		
		fetch(API_BASE_URL + "/api/v1/auth/login/?webapp=True", {
			method: "post",
			body: JSON.stringify(loginValues),
			credentials: "include",
			headers: {
			"Content-Type": "application/json",
			},
		})
        .then((res) => res?.json())
        .then((res) => {
			if (!res?.success) throw new Error(Array.isArray(res?.message) ? res?.message[0] ?? "Failed to login" : res?.message ?? "Failed to login")

			if(res?.two_factor_auth) navigate("/login/verification");
			else {
				
				// saving login attempt details
				dispatch(login(userLoginAttemptWith));
				
				JService.get("/api/v1/auth/retrieve-account-profile/")
				.then((_res) => {
					if(!_res?.success) throw new Error(_res?.message || "Failed to fetch account details");

					
					// saving account details
					dispatch(saveAccountDetails(_res));
	
					// use response data instead of
					// values from redux for clevertap
					reduxAccountRef.current = _res;
	
					let userDetails = _res?.data;
					let server = new URL(API_BASE_URL);
					server = server.host;

					const browser_calls = res?.data?.access_list?.includes("BROWSER CALLING");
	
					let role =
						userDetails?.profile?.role == 1
						? "super admin"
						: userDetails?.profile?.role == 4
						? "admin"
						: userDetails?.profile?.role == 2
						? "user"
						: userDetails?.profile?.role == 3
						? "viewer"
						: "unknown"; // eslint-disable-line
					let integrations = "";
					if (userDetails?.profile?.hubspot_access)
						integrations += "hubspot";
	
					if (userDetails?.profile?.monday_access) {
						if (!integrations) integrations += "monday";
						else integrations += ", monday";
					}
					if (userDetails?.profile?.deskera_access) {
						if (!integrations) integrations += "deskera";
						else integrations += ", deskera";
					}
	
					if (userDetails?.profile?.pipedrive_access) {
						if (!integrations) integrations += "pipedrive";
						else integrations += ", pipedrive";
					}
	
					if (userDetails?.profile?.zoho_phonebridge_access) {
						if (!integrations) integrations += "zohophonebridge";
						else integrations += ", zohophonebridge";
					}
					if (userDetails?.profile?.zoho_access) {
						if (!integrations) integrations += "zoho";
						else integrations += ", zoho";
					}
	
					let payload = {
						Name: userDetails?.first_name + " " + userDetails?.last_name, // fullname
						Identity: userDetails?.email, //email,
						Email: userDetails?.email,
	
						Phone:
						userDetails?.number?.country_code +
						"" +
						userDetails?.number?.phone_number, // string | number, // user phone number
						OrganizationName: userDetails?.profile?.org_name || "",
						OrganizationId: userDetails?.profile?.org_id,
						VirtualNumbers: JSON.stringify(userDetails?.virtual_numbers),
						SuperAdmin: userDetails?.account_holder ? "yes" : "no",
						Role: role,
						IsVerified: userDetails?.profile?.is_verified
						? "verified"
						: "not verified",
						TotalMinutes: userDetails?.profile?.total_minutes,
						// currentSubOrg: JSON.stringify({isPrimaryAccount: userDetails?.}) V2
						Integrations: integrations,
						InboundCalls: userDetails?.profile?.inbound_calls
						? "enabled"
						: "disabled",
						UserEmail: userDetails?.email,
						UserPhoneNumber:
						userDetails?.number?.country_code +
						"" +
						userDetails?.number?.phone_number, // string | number, // user phone number
						// when did they join ?
						BrowserCalls: browser_calls
						? "enabled"
						: "disabled",
						NotifyEmails: userDetails?.profile?.notify_emails
						? "true"
						: "false",
						MissedCallNotifications: userDetails?.profile
						?.missed_call_notifications
						? "true"
						: "false",
						PrimaryVirtualNumber: JSON.stringify(
						userDetails?.profile?.primary_virtual_number
						),
						FeatureAddOns: JSON.stringify(
						userDetails?.profile?.feature_add_ons || []
						),
					};
					clevertap.onUserLogin.push({ Site: payload });
	
					pushToClevertap("User login", true);
					
	
					// account holder can add multiple accounts, and some apis
					// which are account/org specific like retrieve-call-logs/orgId,
					// retrieve-org/orgId etc. need the orgId suffix
					if (_res?.data?.profile?.account_holder) {
						JService.get("/api/v1/auth/organizations/")
						.then((__res) => {
							if (!__res?.success) throw new Error(__res?.message || "Could not retrieve organizations");
							
							dispatch(setAllOrgsDetails(__res?.data));
							dispatch(saveSelectedOrg(__res?.data?.find((rd) => rd?.primary_account)?.id ||""));
							loginRedirect();
						})
						.catch((__err) => enqueueSnackbar(__err?.message, { variant: "error"}))
					}
					// for users accessing the shared-call recording link
					else loginRedirect();
				})
				.catch((_err) => enqueueSnackbar(_err?.message, { variant: "error"}))
				.finally(() => (isSubmitting ? setIsSubmitting(false) : null));
			}
        })
        .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
        .finally(() => {
			resetStates()
        });
    }
  };

  const handleForgotPasswordClick = (e) => {
	e.preventDefault()
	navigate("/forgot-password");
  }

  const resetStates = (arg="password") => {
	setLoginValues(prev => ({...prev, [arg]: ""}));
	setIsSubmitting(false);
  }

  return (
    <>
      <div className="login-container">
        <div className="login-art">
		  <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
          <img src={dotsTop} />
          <img src={vectorArt} />
          <img src={dotsBottom} />
          <p className="regular-font t5">
            Simplify and Automate
            <br />
            Your Calling Process
          </p>
        </div>
        <h1 style={{ position: "absolute", visibility: "hidden" }}>
          Frejun Login
        </h1>
        <div className="login-form">
          <p className="title medium-font t5">Login</p>
          <form id="login-form-form" onSubmit={handleLoginSubmit}>
            <label className="medium-font t7">
              Email
              <input
                className="regular-font t6 ui--input ui--input-email"
                style={{ ...localStyles.inputField }}
                name="email"
                type="text"
                value={loginValues?.email}
                onChange={(e) => handleLoginChange(e)}
              />
            </label>

            <label className="medium-font t7">
              Password
              <input
                className="regular-font t6 ui--input ui--input-password"
                style={{ ...localStyles.inputField }}
                name="password"
                type="password"
                value={loginValues?.password}
                onChange={(e) => handleLoginChange(e)}
              />
            </label>

            <label className="to-forgot-password-container">
              <a
			  	href="/forgot-password"
                className="to-forgot-password regular-font t7 ui--forgot-password to-sign-up"
                onClick={(e) => handleForgotPasswordClick(e)}
              >
                Forgot Password?
              </a>
            </label>

            <Button
			  className="ui--login ui--button"
              form="login-form-form"
              type="submit"
              disabled={!loginValidation(loginValues).isValid}
              hierarchy="green"
              variant="contained"
              buttonText="Login"
              isLoading={isSubmitting}
              onClick={handleLoginSubmit}
              onSubmit={handleLoginSubmit}
              styleOverride={{ left: "50%", transform: "translate(-50%)" }}
            />
          </form>
          <div className="regular-font t7 to-sign-up-container">
            Don't have an account?
            <a href="/signup" target="_blank" rel="noopener noreferrer" className="to-sign-up">
              Sign up
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
